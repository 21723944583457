import AddPriceTable from './AddPriceTableDialog';
import EditPriceTable from './EditPriceTable';
import AddInvoiceDialog from './AddInvoiceDialog';
import LinkInvoiceItemToPriceTable from './LinkInvoiceItemToPriceTable';

const DialogTypes = type =>
  ({
    ADD_PRICE_TABLE: AddPriceTable,
    UPDATE_PRICE_TABLE: EditPriceTable,
    ADD_INVOICE: AddInvoiceDialog,
    LINK_PRICE_TABLE: LinkInvoiceItemToPriceTable
  }[type]);

export default DialogTypes;
