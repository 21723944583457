/* eslint-disable react/prop-types */
import React from 'react';
import NumberFormat from './NumberFormat';

const BraziliaPriceFormat = props => (
  <NumberFormat
    prefix={'R$ '}
    decimalScale={props.decimalScale || 4}
    {...props}
  />
);

export default BraziliaPriceFormat;
