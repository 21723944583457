export const host = process.env.REACT_APP_HOST_API;
const token = localStorage.getItem('jwt_token');

export const getEndpoint = route => `${host}${route}`;

export const createRequest = method => body => {
  return {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };
};

export const createPostRequest = body => createRequest('POST')(body);
export const createDeleteRequest = body => createRequest('DELETE')(body);
export const createPutRequest = body => createRequest('PUT')(body);
