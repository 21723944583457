import Dashboard from '@material-ui/icons/Dashboard';
import AccountTreeSharpIcon from '@material-ui/icons/AccountTreeSharp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CategoryIcon from '@material-ui/icons/Category';
import ViewListIcon from '@material-ui/icons/ViewList';

export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard/analytics',
    icon: Dashboard,
    auth: ['ADMIN']
  },
  {
    name: 'Categorias',
    path: '/categories',
    icon: CategoryIcon,
    auth: ['ADMIN']
  },
  {
    name: 'Opções',
    path: '/options',
    icon: AccountTreeSharpIcon,
    auth: ['ADMIN']
  },
  {
    name: 'Produtos',
    path: '/products',
    icon: ViewListIcon,
    auth: ['ADMIN']
  },
  {
    name: 'Tabelas de preço',
    path: '/price-tables',
    icon: MonetizationOnIcon,
    auth: ['ADMIN']
  }
];
