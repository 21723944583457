/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { updatePriceTable } from 'app/redux/actions/PriceTable.actions';
import ReactNumberFormat from 'react-number-format';

const units = ['m²', 'cm²', 'quantidade'];

const styles = theme => ({
  container: {
    display: 'grid',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

const Dialog = ({
  enqueueSnackbar: snack,
  classes,
  onClose,
  priceTable,
  onSetPriceTable
}) => {
  const [unit, setUnit] = useState(priceTable.unit);
  const [cost, setCost] = useState(priceTable.cost);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    snack('Atualizando tabela de preço...', {
      variant: 'info',
      autoHideDuration: 2000
    });
    dispatch(
      updatePriceTable(
        priceTable._id,
        priceTable.name,
        unit,
        cost.floatValue,
        snack
      )
    );
    handleClose();
  };

  const handleClose = () => onClose();

  return (
    <div>
      <DialogTitle id="form-dialog-title">Editar tabela de preço</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              disabled
              margin="dense"
              id="name"
              label="Nome"
              value={priceTable.name}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="unit">Unidade da tabela de preço</InputLabel>
            <Select
              className={classes.select}
              value={unit}
              onChange={e => setUnit(e.target.value)}
              input={<Input id="unit" />}
            >
              {units.map(u => (
                <MenuItem key={u} value={u}>
                  {u}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <ReactNumberFormat
              margin="dense"
              id="custo"
              name="custo"
              label="Custo"
              fullWidth
              defaultValue={cost}
              // format;
              customInput={TextField}
              // value={}
              fixedDecimalScale
              decimalSeparator={','}
              thousandSeparator={'.'}
              decimalScale={4}
              onValueChange={_value => setCost(_value)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <ReactNumberFormat
              margin="dense"
              name="higherSale"
              id="higherSale"
              label="Maior margem de venda"
              fullWidth
              disabled={true}
              customInput={TextField}
              value={
                priceTable.highestSalePercentage
                  ? priceTable.highestSalePercentage
                  : 0
              }
              suffix={'%'}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <ReactNumberFormat
              margin="dense"
              name="lowerSale"
              id="lowerSale"
              label="Menor margem de venda"
              fullWidth
              customInput={TextField}
              disabled={true}
              value={
                priceTable.lowestPercentageOfSale
                  ? priceTable.lowestPercentageOfSale
                  : 0
              }
              suffix={'%'}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Enviar alteração
        </Button>
      </DialogActions>
    </div>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSetPriceTable: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  priceTable: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(Dialog);
