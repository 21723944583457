/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import XMLParser from 'react-xml-parser';
import { Link } from 'react-router-dom';

const styles = theme => ({
  container: {
    display: 'grid',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

const Dialog = ({ enqueueSnackbar: snack, classes, open, onClose }) => {
  const handleSubmit = () => {
    handleClose();
  };

  const handleClose = () => onClose();

  const inputRef = useRef();
  const [fileName, setFileName] = useState('');
  const [itemsFromInvoice, setItemsFromInvoice] = useState([]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    let [file] = e.target.files;

    setFileName(file.name);

    reader.readAsText(file);

    reader.onload = evt => {
      const readerData = evt.target.result;

      const parser = new DOMParser();
      const xml = parser.parseFromString(readerData, 'text/xml');

      var NewXml = new XMLParser().parseFromString(
        new XMLSerializer().serializeToString(xml.documentElement)
      );

      const items = NewXml.children[0].children[0].children.filter(
        item => item.name === 'det'
      );

      setItemsFromInvoice(
        items.map(item => ({
          unitValue: item.children[0].children.find(c => c.name === 'vUnCom')
            .value,
          tax: item.children[1].children
            .find(c => c.name === 'IPI')
            ?.children[1]?.children?.find(c => c.name === 'pIPI')?.value,
          xProd: item.children[0].children.find(c => c.name === 'xProd').value,
          cProd: item.children[0].children.find(c => c.name === 'cProd').value
        }))
      );
    };
  };

  return (
    <div>
      <MuiDialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Cadastrar tabela de preço
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <div className="fileinput text-center">
                <input
                  hidden
                  type="file"
                  onChange={handleImageChange}
                  ref={inputRef}
                  accept="application/xml"
                />
                <div>
                  <Button
                    style={{
                      color: fileName ? '#1ba10a' : '#7467EF',
                      borderColor: fileName ? '#1ba10a' : '#7467EF'
                    }}
                    variant="outlined"
                    size="large"
                    onClick={handleClick}
                    startIcon={
                      fileName ? (
                        <CheckCircleOutlineIcon />
                      ) : (
                        <CloudUploadIcon />
                      )
                    }
                  >
                    {fileName ? fileName : 'Selecione um arquivo'}
                  </Button>
                </div>
              </div>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            <Link
              to={{
                pathname: `/link-invoice`,
                state: {
                  fromRedirect: true,
                  items: itemsFromInvoice
                }
              }}
            >
              Avançar
            </Link>
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(Dialog);
