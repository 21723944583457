/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withSnackbar } from 'notistack';
import Container from '@material-ui/core/Container';
import { Breadcrumb } from 'matx';
import PropTypes from 'prop-types';
import ItemDatatable from './ItemDatatable';
import DialogContainer from './Dialogs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPriceTables } from 'app/redux/actions/PriceTable.actions';
import { getPriceTables } from 'app/redux/selectors/PriceTable.selectors';

const LinkInvoiceToPriceTable = props => {
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState('LINK_PRICE_TABLE');
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();

  const handleOpen = type => {
    setOpen(true);
    setDialogType(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowUpdate = (item, type) => {
    handleOpen(type);
    setItem(item);
  };

  useEffect(() => {
    dispatch(fetchPriceTables());
  }, []);

  const priceTables = useSelector(store => getPriceTables(store));

  return (
    <>
      <Container maxWidth="xl" className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: 'Vincular nota fiscal', path: '/link-invoice' }
            ]}
          />
        </div>
        {open && (
          <DialogContainer
            item={item}
            type={dialogType}
            open={open}
            onClose={handleClose}
            {...props}
          />
        )}
        <ItemDatatable
          data={props.location.state.items}
          priceTables={priceTables}
          onOpen={handleOpen}
          onUpdate={handleRowUpdate}
          {...props}
        />
      </Container>
    </>
  );
};

LinkInvoiceToPriceTable.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(LinkInvoiceToPriceTable);
