/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import ReactNumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPriceRange } from '../Price/Dialogs/GeneratePrice';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { addPriceRange } from 'app/redux/actions/Price.actions';
import { updatePriceTable } from 'app/redux/actions/PriceTable.actions';
import { useEffect } from 'react';

const styles = theme => ({
  container: {
    display: 'grid',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

const unitsObj = {
  'm²': { value: 10000, precoDivisor: 1 },
  'cm²': { value: 1, precoDivisor: 10000 }
};

const Dialog = ({ enqueueSnackbar: snack, classes, open, onClose, item }) => {
  const [priceTables, setPriceTables] = useState(item.priceTables);
  const [priceTable, setPriceTable] = useState(null);
  const [cost, setPriceCost] = useState('');
  const [formattedCost, setFormattedCost] = useState();
  const [higherSalesMargin, setHigherSalesMargin] = useState('');
  const [lowerSalesMargin, setLowerSalesMargin] = useState('');
  const [
    formattedHigherSalesMargin,
    setFormattedHigherSalesMargin
  ] = useState();
  const [formattedLowerSalesMargin, setFormattedLowerSalesMargin] = useState();

  const handleEnterKey = e => {
    if (e.keyCode == 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const { unit } = priceTable;
    snack('Gerando intervalo de preços...', {
      variant: 'info',
      autoHideDuration: 4000
    });

    dispatch(
      updatePriceTable(
        priceTable._id,
        priceTable.name,
        unit,
        parseFloat(cost),
        item.cProd,
        item.xProd,
        snack
      )
    );

    const prices = getPriceRange({
      priceValue: parseFloat(cost),
      higherSalesMargin: parseFloat(higherSalesMargin),
      lowerSalesMargin: parseFloat(lowerSalesMargin),
      unit: unitsObj[unit].value, // metro ou centimentros quadrados  - cm² m²,
      precoDivisor: unitsObj[unit].precoDivisor
    });

    dispatch(
      addPriceRange(
        prices,
        unit,
        priceTable._id,
        parseFloat(higherSalesMargin),
        parseFloat(lowerSalesMargin),
        snack
      )
    );

    setPriceTable({
      ...priceTable,
      highestSalePercentage: parseFloat(higherSalesMargin),
      lowestPercentageOfSale: parseFloat(lowerSalesMargin)
    });

    handleClose();
  };

  const handleClose = () => onClose();

  useEffect(() => {
    const selectedPriceTable = priceTables.find(
      priceTable =>
        priceTable.cProd === item.cProd || priceTable.xProd === item.xProd
    );

    if (selectedPriceTable) {
      setPriceTable(selectedPriceTable);
    }

    setPriceTables(
      priceTables.sort((a, b) =>
        a.name.localeCompare(b.name, 'pt-br', { sensitivity: 'base' })
      )
    );
  }, []);

  useEffect(() => {
    if (!priceTable) return;
    setPriceCost(priceTable.cost);
    setHigherSalesMargin(priceTable.highestSalePercentage);
    setLowerSalesMargin(priceTable.lowestPercentageOfSale);
  }, [priceTable]);

  return (
    <div>
      <MuiDialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Vincular item a uma tabela de preço
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                disabled
                margin="dense"
                id="name"
                label="Produto"
                value={item.xProd}
                fullWidth
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <ReactNumberFormat
                margin="dense"
                id="unitValueWithTaxIPI"
                name="Valor c/ IPI"
                label="Valor c/ IPI"
                fullWidth
                defaultValue={item.unitValueWithTaxIPI}
                customInput={TextField}
                fixedDecimalScale
                decimalSeparator={'.'}
                thousandSeparator={','}
                decimalScale={4}
                disabled={true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="priceTables">Tabela de preço</InputLabel>
              <Select
                className={classes.select}
                value={priceTable}
                onChange={e => {
                  setPriceTable(e.target.value);
                }}
                input={<Input id="priceTable" />}
              >
                {priceTables.map(u => (
                  <MenuItem key={u} value={u}>
                    {u.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {priceTable ? (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="Unit"
                    name="Unit"
                    label="Unidade"
                    fullWidth
                    disabled={true}
                    value={priceTable.unit}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <ReactNumberFormat
                    margin="dense"
                    id="custo"
                    name="custo"
                    label="Custo em m²"
                    fullWidth
                    // format;
                    customInput={TextField}
                    value={formattedCost ? formattedCost : cost}
                    fixedDecimalScale
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    decimalScale={4}
                    onValueChange={values => {
                      const { formattedValue, value } = values;

                      setPriceCost(value);
                      setFormattedCost(formattedValue);
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <ReactNumberFormat
                    margin="dense"
                    name="higherSale"
                    id="higherSale"
                    label="Maior margem de venda"
                    fullWidth
                    // format
                    customInput={TextField}
                    onValueChange={values => {
                      const { formattedValue, value } = values;

                      setHigherSalesMargin(value);
                      setFormattedHigherSalesMargin(formattedValue);
                    }}
                    value={
                      formattedHigherSalesMargin
                        ? formattedHigherSalesMargin
                        : higherSalesMargin
                    }
                    suffix={'%'}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <ReactNumberFormat
                    margin="dense"
                    name="lowerSale"
                    id="lowerSale"
                    label="Menor margem de venda"
                    fullWidth
                    onKeyDown={handleEnterKey}
                    // format
                    customInput={TextField}
                    value={
                      formattedLowerSalesMargin
                        ? formattedLowerSalesMargin
                        : lowerSalesMargin
                    }
                    onValueChange={values => {
                      const { formattedValue, value } = values;

                      setLowerSalesMargin(value);
                      setFormattedLowerSalesMargin(formattedValue);
                    }}
                    suffix={'%'}
                  />
                </FormControl>
              </>
            ) : null}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Gerar novos intervalos de preço
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default withStyles(styles)(Dialog);
