/* eslint-disable react/prop-types */
import React from 'react';
import MuiDatatable from 'app/components/common/tables/MuiDatatable';
import { withStyles } from '@material-ui/core/styles';
import Loading from './LoadingSkeleton';
import classNames from 'classnames';
import BraziliaPriceFormat from 'app/components/common/format/NumberFormat/BraziliaPriceFormat';
import MoreHorizIcon from 'app/components/common/icons/MoreHorizIcon';

const styles = {
  EditCell: { textAlign: 'right' },
  NameCell: { fontWeight: 500 }
};

const ItemDatatable = ({
  enqueueSnackbar: snack,
  classes,
  data,
  onUpdate,
  priceTables
}) => {
  const columns = [
    {
      name: 'xProd',
      label: 'Produto',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => {
          return {
            className: classNames({ [classes.NameCell]: true })
          };
        }
      }
    },
    {
      name: 'unitValue',
      label: 'Valor unitário',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => {
          return {
            className: classNames({ [classes.NameCell]: true })
          };
        },
        customBodyRender: function renderFormatedValue(value, tableMeta) {
          return (
            <BraziliaPriceFormat
              decimalScale={2}
              key={tableMeta.rowIndex}
              value={value}
            />
          );
        }
      }
    },
    {
      name: 'tax',
      label: 'Porcentagem de IPI',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => {
          return {
            className: classNames({ [classes.NameCell]: true })
          };
        },
        customBodyRender: function renderFormatedValue(value, tableMeta) {
          return <p key={tableMeta.rowIndex}>{value}%</p>;
        }
      }
    },
    {
      name: ' ',
      label: 'Valor c/ IPI',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => {
          return {
            className: classNames({ [classes.NameCell]: true })
          };
        },
        customBodyRender: function renderFormatedValue(value, tableMeta) {
          const [, unitValue, taxIPI] = tableMeta.rowData;
          const unitValueWithTaxIPI =
            unitValue * (taxIPI / 100) + parseFloat(unitValue);
          return (
            <BraziliaPriceFormat
              decimalScale={2}
              key={tableMeta.rowIndex}
              value={unitValueWithTaxIPI}
            />
          );
        }
      }
    },
    {
      name: 'cProd',
      label: ' ',
      options: {
        display: false,
        sort: false,
        filter: false
      }
    },
    {
      name: ' ',
      label: ' ',
      options: {
        sort: false,
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => {
          const [xProd, unitValue, taxIPI, , cProd] = tableMeta.rowData;
          const unitValueWithTaxIPI =
            unitValue * (taxIPI / 100) + parseFloat(unitValue);

          const item = {
            xProd,
            unitValue,
            taxIPI,
            cProd,
            unitValueWithTaxIPI,
            priceTables,
          };

          return (
            <>
              <MoreHorizIcon
                key={tableMeta.columnIndex}
                onClick={() => onUpdate(item, 'LINK_PRICE_TABLE')}
              />
            </>
          );
        },
        setCellProps: () => {
          return {
            className: classNames({ [classes.EditCell]: true })
          };
        }
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    rowHover: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 50, 100],
    textLabels: {
      body: {
        noMatch: <Loading />
      }
    }
  };

  return (
    <>
      <MuiDatatable
        title={<h2>Items</h2>}
        data={data}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default withStyles(styles)(ItemDatatable);
